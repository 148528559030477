import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  opened: false
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnack: (state, action) => {
      const { message, severity = 'info' } = action.payload
      state.message = message
      state.severity = severity
      state.opened = true
    },
    hideSnack: (state) => {
      state.opened = false
    }
  }
})

export const { showSnack, hideSnack } = snackbarSlice.actions

export const selectOpen = state => state.snackbar.opened
export const selectMessage = state => state.snackbar.message
export const selectSeverity = state => state.snackbar.severity

export default snackbarSlice.reducer
