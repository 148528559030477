import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createDemo, getAllDemo, selectDemo } from '../../features/demo'
import i18n from '../../i18n'
import Entity from '../entity'
import Snackbar from '../snackbar/snackbar'
import './App.css'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'

  },
  element: {
    flex: 1
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem'
  }
}))

function App () {
  const dispatch = useDispatch()
  const classes = useStyles()
  const entities = useSelector(selectDemo)

  useEffect(() => {
    dispatch(getAllDemo())
  }, [dispatch])

  return (
    <Container maxWidth='sm' className={classes.container}>
      <Typography component='h2' variant='h3' gutterBottom>{i18n.title}</Typography>
      {entities.map(e => <Entity key={e.code} entity={e} />)}
      <div className={classes.addButtonContainer}>
        <Button onClick={() => dispatch(createDemo())} variant='contained'><Add /> </Button>
      </div>
      <Snackbar />
    </Container>
  )
}

export default App
