import { configureStore } from '@reduxjs/toolkit'
import snackbar from './features/snackbar'
import demo from './features/demo'

export const store = configureStore({
  reducer: {
    demo,
    snackbar
  }
})
