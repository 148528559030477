import MaterialSnackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { hideSnack, selectMessage, selectOpen, selectSeverity } from '../../features/snackbar'

const Snackbar = ({ duration = 5000 }) => {
  const dispatch = useDispatch()
  const open = useSelector(selectOpen)
  const message = useSelector(selectMessage)
  const severity = useSelector(selectSeverity)
  const handleClose = () => {
    dispatch(hideSnack())
  }
  return (
    <MaterialSnackbar
      open={open}
      autoHideDuration={duration}
      data-testid='snackbar'
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>{message}</Alert>
    </MaterialSnackbar>
  )
}

export default Snackbar
