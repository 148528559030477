import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import config from '../config'
import { httpDelete, httpGet, httpPatch, httpPost } from '../utils/fetch'
import i18n from '../i18n'
import { showSnack } from './snackbar'

// const mockedEntities = [
//   { code: 1, name: 'mit', botId: 'test1', voice: 'albero1', chat: 'albero2' },
//   { code: 2, name: 'acegas', botId: 'test2', voice: 'albero1', chat: 'albero2' }
// ]

const initialState = {
  entities: [],
  fetching: false,
  updating: false,
  deleting: false,
  fetchError: '',
  updateError: ''
}

export const getAllDemo = createAsyncThunk(
  'demo/getAll',
  async (params, { dispatch }) => {
    try {
      // const entities = [...mockedEntities]
      const entities = await httpGet(config.SERVER_URI)
      dispatch(showSnack({ message: i18n.api.getAll.success, severity: 'success' }))
      return entities
    } catch (err) {
      if (err.statusCode) {
        dispatch(showSnack({ message: i18n.httpErrors[err.statusCode], severity: 'error' }))
      } else {
        dispatch(showSnack({ message: err.message, severity: 'error' }))
      }
      throw err
    }
  }
)

export const postDemo = createAsyncThunk(
  'demo/post',
  async (entity, { dispatch }) => {
    try {
      entity = { ...entity }
      delete entity.unsaveChanges
      const updatedEntity = await httpPost(config.SERVER_URI, entity)
      dispatch(showSnack({ message: i18n.api.getAll.success, severity: 'success' }))
      return updatedEntity
    } catch (err) {
      if (err.statusCode) {
        dispatch(showSnack({ message: i18n.httpErrors[err.statusCode], severity: 'error' }))
      } else {
        dispatch(showSnack({ message: err.message, severity: 'error' }))
      }
      throw err
    }
  }
)

export const patchDemo = createAsyncThunk(
  'demo/patch',
  async (entity, { dispatch }) => {
    try {
      entity = { ...entity }
      delete entity.unsaveChanges
      const updatedEntity = await httpPatch(config.SERVER_URI + '/' + entity.code, entity)
      dispatch(showSnack({ message: i18n.api.getAll.success, severity: 'success' }))
      return updatedEntity
    } catch (err) {
      if (err.statusCode) {
        dispatch(showSnack({ message: i18n.httpErrors[err.statusCode], severity: 'error' }))
      } else {
        dispatch(showSnack({ message: err.message, severity: 'error' }))
      }
      throw err
    }
  }
)

export const deleteDemo = createAsyncThunk(
  'demo/delete',
  async (entity, { dispatch }) => {
    try {
      await httpDelete(config.SERVER_URI + '/' + entity.code)
      dispatch(showSnack({ message: i18n.api.getAll.success, severity: 'success' }))
      return entity.code
    } catch (err) {
      if (err.statusCode) {
        dispatch(showSnack({ message: i18n.httpErrors[err.statusCode], severity: 'error' }))
      } else {
        dispatch(showSnack({ message: err.message, severity: 'error' }))
      }
      throw err
    }
  }
)

const getFirstCodeAvaiable = (state) => {
  const max = () => state.entities.reduce((a, b) => Math.max(a, b.code), 0)
  const missingNumber = () => {
    const indexMismatch = state.entities.findIndex((e, index) => index + 1 !== e.code)
    if (indexMismatch) {
      return indexMismatch + 1
    }
    return undefined
  }
  return missingNumber() || max() + 1
}

export const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    mockDeleteDemo: (state, action) => {
      const entity = action.payload
      const index = state.entities.findIndex(e => e.code === entity.code)
      if (index >= 0) {
        state.entities.splice(index, 1)
      }
    },
    updateEntity: (state, action) => {
      const { key, value, entity } = action.payload
      const index = state.entities.findIndex(e => e.code === entity.code)
      state.entities[index] = { ...entity, [key]: value, unsaveChanges: true }
    },
    createDemo: (state, action) => {
      const code = getFirstCodeAvaiable(state)
      state.entities.splice(code - 1, 0, { code, unsaveChanges: true, new: true, expanded: true })
    },
    duplicateDemo: (state, action) => {
      const code = getFirstCodeAvaiable(state)
      state.entities.splice(code - 1, 0, { ...action.payload, code, unsaveChanges: true, new: true, expanded: true, name: `Copy of ${action.payload.name}` })
    },
    toggleExpandEntity: (state, action) => {
      const myEntity = state.entities.find(e => e.code === action.payload.code)
      myEntity.expanded = !myEntity.expanded
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDemo.pending, (state, action) => {
      state.fetching = true
    })
    builder.addCase(getAllDemo.rejected, (state, action) => {
      state.fetching = false
      state.fetchError = action.payload
      console.log(action)
    })
    builder.addCase(getAllDemo.fulfilled, (state, action) => {
      state.fetching = false
      state.entities = [...action.payload]
    })

    builder.addCase(postDemo.pending, (state, action) => {
      state.updating = true
    })
    builder.addCase(postDemo.rejected, (state, action) => {
      state.updating = false
      state.updateError = action.payload
    })
    builder.addCase(postDemo.fulfilled, (state, action) => {
      state.updating = false
      const entity = action.payload
      const index = state.entities.findIndex(e => e.code === entity.code)
      state.entities[index] = entity
    })

    builder.addCase(patchDemo.pending, (state, action) => {
      state.updating = true
    })
    builder.addCase(patchDemo.rejected, (state, action) => {
      state.updating = false
      state.updateError = action.payload
    })
    builder.addCase(patchDemo.fulfilled, (state, action) => {
      state.updating = false
      const entity = action.payload
      const index = state.entities.findIndex(e => e.code === entity.code)
      state.entities[index] = entity
    })

    builder.addCase(deleteDemo.pending, (state, action) => {
      state.deleting = true
    })
    builder.addCase(deleteDemo.rejected, (state, action) => {
      state.updating = false
      state.updateError = action.payload
    })
    builder.addCase(deleteDemo.fulfilled, (state, action) => {
      state.updating = false
      const code = action.payload
      const index = state.entities.findIndex(e => e.code === code)
      state.entities.splice(index, 1)
    })
  }
})

export const selectDemo = (state) => state.demo.entities
// Action creators are generated for each case reducer function
export const { createDemo, duplicateDemo, updateEntity, mockDeleteDemo, toggleExpandEntity } = demoSlice.actions
export default demoSlice.reducer
