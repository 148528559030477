const i18n = {
  title: 'Gestore Demo responsa',
  element: {
    voiceOptions: 'Opzioni voce',
    overrideTitle: 'Override alberi decisionali',
    modify: 'Modifica demo {0}'
  },
  api: {
    getAll: {
      success: 'Demo recuperate correttamente'
    },
    update: {
      success: 'Demo correttamente salvata'
    },
    delete: {
      success: 'Demo correttamente eliminata'
    },
    clone: {
      success: 'Demo correttamente clonata'
    }
  },
  httpErrors: {
    400: 'Request error',
    401: 'Unauthorized',
    404: 'Bot not foud',
    500: 'Server error',
    [undefined]: 'An error occurred'
  }
}

export default i18n
