import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import i18n from '../i18n'
import { useDispatch } from 'react-redux'
import { duplicateDemo, patchDemo, updateEntity, postDemo, deleteDemo, toggleExpandEntity } from '../features/demo'
import { Button, Divider, InputLabel, MenuItem, Select, Slider } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,
    flexBasis: '20%',
    flexShrink: 0
  },
  secondaryHeading: (props) => {
    return {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: props.unsaveChanges ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
      fontStyle: props.unsaveChanges ? 'italic' : 'normal'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  title: {
    marginTop: theme.typography.pxToRem(15)
  },
  actionsContainer: {
    marginTop: theme.typography.pxToRem(15),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  action: {
    marginLeft: '1rem'
  }
}))

const Entity = ({ entity }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(entity.name)
  const [botId, setBotId] = useState(entity.botId)
  const [voice, setVoice] = useState(entity.voice)
  const [voiceName, setVoiceName] = useState(entity.voiceName)
  const [voiceDefaultRate, setVoiceDefaultRate] = useState(entity.voiceDefaultRate)
  const [voiceSpeechModel, setVoiceSpeechModel] = useState(entity.voiceSpeechModel)
  const [whatsApp, setWhatsApp] = useState(entity.whatsApp)
  const [expanded] = useState(entity.expanded)
  const classes = useStyles(entity)

  const deleteEntity = () => {
    if (window.confirm(`Sei sicuro di voler eliminare la demo ${entity.code}?`)) {
      dispatch(deleteDemo(entity))
    }
  }

  const onSaveClicked = (e) => {
    if (e.new) {
      dispatch(postDemo(entity))
    } else {
      dispatch(patchDemo(entity))
    }
  }
  return (
    <Accordion expanded={expanded} onClick={() => { dispatch(toggleExpandEntity(entity)) }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>#{entity.code}</Typography>
        <Typography className={classes.secondaryHeading}>NOME: {entity.name}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography className={classes.title} component='h5' variant='h5' gutterBottom>{i18n.element.modify.replace('{0}', entity.code)}</Typography>
        <TextField label='Nome' required value={name || ''} onChange={(e) => setName(e.target.value)} onBlur={(e) => dispatch(updateEntity({ entity, key: 'name', value: e.target.value }))} />
        <TextField label='BOT ID' required value={botId || ''} onChange={(e) => setBotId(e.target.value)} onBlur={(e) => dispatch(updateEntity({ entity, key: 'botId', value: e.target.value }))} />
        <br />
        <Divider />
        <Typography className={classes.title} component='h5' variant='h6' gutterBottom>{i18n.element.overrideTitle}</Typography>
        <TextField label='Voice' value={voice || ''} onChange={(e) => setVoice(e.target.value)} onBlur={(e) => dispatch(updateEntity({ entity, key: 'voice', value: e.target.value }))} />
        <TextField label='WhatsApp' value={whatsApp || ''} onChange={(e) => setWhatsApp(e.target.value)} onBlur={(e) => dispatch(updateEntity({ entity, key: 'chat', value: e.target.value }))} />
        <br />
        <Divider />
        <Typography className={classes.title} component='h6' variant='h6' gutterBottom>{i18n.element.voiceOptions}</Typography>

        <InputLabel id='voice-name-label'>Voice Name</InputLabel>
        <Select
          label='Voice Name'
          value={voiceName || 'Polly.Carla'}
          onChange={(e) => setVoiceName(e.target.value)}
          onBlur={(e) => dispatch(updateEntity({ entity, key: 'voiceName', value: e.target.value }))}
        >
          <MenuItem value='Polly.Bianca'>Bianca</MenuItem>
          <MenuItem value='Polly.Carla'>Carla</MenuItem>
        </Select>
        <br />
        {/* <Typography id='speech-rate-slider' gutterBottom>
          Speech default rate
        </Typography> */}
        <InputLabel id='speech-rate-slider'>Speech default rate</InputLabel>
        <Slider
          aria-labelledby='speech-rate-slider'
          step={1}
          marks
          min={70}
          max={130}
          valueLabelDisplay='auto'
          value={voiceDefaultRate || 100}
          onChange={(e, value) => setVoiceDefaultRate(value)}
          onBlur={(e) => dispatch(updateEntity({ entity, key: 'voiceDefaultRate', value: voiceDefaultRate }))}
        />
        <br />
        <InputLabel id='voice-speech-model-label'>Speech to Text Model</InputLabel>
        <Select
          labelId='voice-speech-model-label'
          value={voiceSpeechModel || 'numbers_and_commands'}
          onChange={(e) => setVoiceSpeechModel(e.target.value)}
          onBlur={(e) => dispatch(updateEntity({ entity, key: 'voiceSpeechModel', value: e.target.value }))}
        >
          <MenuItem value='default'>default</MenuItem>
          <MenuItem value='numbers_and_commands'>numbers_and_commands</MenuItem>
          <MenuItem value='phone_call'>phone_call</MenuItem>
        </Select>
        <div className={classes.actionsContainer}>
          <Button className={classes.action} color='secondary' variant='contained' onClick={deleteEntity}>Elimina</Button>
          <Button className={classes.action} variant='contained' onClick={() => dispatch(duplicateDemo(entity))}>Clona</Button>
          <Button className={classes.action} disabled={!entity.unsaveChanges} color='primary' variant='contained' onClick={() => onSaveClicked(entity)}>Save</Button>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default Entity
