import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/app/App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { Provider } from 'react-redux'
import { createMuiTheme, ThemeProvider, unstable_createMuiStrictModeTheme as createMuiStrictModeTheme } from '@material-ui/core/styles'

const DEBUG = process.env.NODE_ENV === 'development'

const createTheme = DEBUG ? createMuiStrictModeTheme : createMuiTheme

const theme = createTheme()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>,
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
